<script>
import Layout from "./../../../layouts/main";
import appConfig from "../../../../app.config";

export default {
  page: {
    meta: [{ name: "Bitget integration", content: appConfig.description }],
  },
  data() {
    return {
    };
  },
  components: {
    Layout
  },
};
</script>

<template>
  <Layout>
      <div class="row">
            <div class="col-lg-12">
                <!-- end card -->

                <div class="row justify-content-evenly">

                  <!-- Base Example -->
                  <div class="accordion" id="default-accordion-example">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                          Шаг 1. Регистрация
                        </button>
                      </h2>
                      <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>1. Пожалуйста, перейдите по ссылке для того, что бы начать регистрация на Bitget <a href="https://www.bitget.com/ru/referral/register?from=referral&clacCode=8VQDFRMK">https://www.bitget.com</a></p>
                          <p>2. Введите адрес электронной почты и пароль.</p>
                          <p>П.С. Для регистрации, Вы можете использоваться свою существующую Google или Apple учетную запись.</p>
                          <img src="/Instruction/Bitget/01.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <p>3. Вы получите письмо с верификационном кодом. Введите 6 значный код и закончите регистрацию.</p>
                          <img src="/Instruction/Bitget/02.jpg" class="img-fluid border-dotted" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                          Шаг 2. Купите криптовалюту или переведите ее с другого места на кошелек Bitget
                        </button>
                      </h2>
                      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>У Вас есть 2 варианта. Если у Вас есть учетная запись на другой бирже или крипто кошелек, Вы можете сделать перевод средств от туда.</p>
                          <br>
                          <p>1. Пожалуйста, перейдите по ссылке на страницу <a href="https://www.bitget.com/ru/asset/recharge">депозита.</a></p>
                          <img src="/Instruction/Bitget/03.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <p>2. Если Вы хотите приобрести крипто валюту используя банковскую ракту или банковский перевод, перейдите по ссылке <a href="https://www.bitget.com/ru/p2p-trade/quick">купить одним нажатием.</a></p>
                          <img src="/Instruction/Bitget/21.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <p>Пожалуйста, следуйте инструкции на Bitget. </p>

                          <p>Если все было сделано корректно, Вы увидите свои активы, перейдя по этой ссылке -> <a href="https://www.bitget.com/ru/asset">Мои активы</a>.</p>

                          <div class="alert alert-warning" role="alert">
                            Для трейдинга Вам понадобится <strong>USDT</strong>.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading3">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                          Шаг 3. Перевод своих активов на нужный счет
                        </button>
                      </h2>
                      <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>Для того, что бы открыть фьючерную сделку, на фьючерном счете должны быть необходимые активы (USDT-M).</p>
                          <img src="/Instruction/Bitget/09.jpg" class="img-fluid border-dotted" />
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading4">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                          Шаг 4. Создать API ключ
                        </button>
                      </h2>
                      <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="heading4" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>1. Перейдите по указанной ссылке для создания API ключа <a href="https://www.bitget.com/ru/account/newapi ">Управление API</a>.</p>
                          <div class="alert alert-warning" role="alert">
                            Мы настоятельно рекомендуем Вам включить двухфакторную авторизацию.
                          </div>
                          <img src="/Instruction/Bitget/11.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <p>2. Нажмите кнопку «Создать новый API», и вы увидите всплывающее окно.</p>
                          <img src="/Instruction/Bitget/14.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <div class="alert alert-warning" role="alert">
                            Для интеграции нам необходимы <strong>права на чтение</strong> и <strong>трейдинг</strong> доступы. Убедитесь, что Вы добавили IP-адрес нашего сервера <strong>52.28.48.36</strong>.
                          </div>
                          <div class="alert alert-danger" role="alert">
                            Не устанавливайте флажки <strong>Перевод</strong> и <strong>Вывод средств</strong>.
                          </div>
                          <p>3. В результате успешного результата Вы увидите вот такой экран.</p>
                          <img src="/Instruction/Bitget/15.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <div class="alert alert-warning" role="alert">
                            Пожалуйста, сохраните секретный ключ для его защиты - он понадобится Вам позже. Из соображений безопасности Вы не можете вернуться и просмотреть его снова. Если Вы потеряете его, Вам нужно будет сделать все шаги еще раз!
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading5">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapse5">
                          Шаг 5. Интеграция с нашей системой
                        </button>
                      </h2>
                      <div id="collapse5" class="accordion-collapse collapse" aria-labelledby="heading5" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>Перейдите по указанной <a href="https://damask-trading.com/api-keys">ссылке</a> для того что бы добавить API ключ в нашу систему.</p>
                          <p>Чтобы заполнить все обязательные поля, используйте данные из предыдущего шага.</p>
                          <ul>
                            <li>Api ключ = Доступ к ключу API</li>
                            <li>Секретный ключ = Секретный ключ</li>
                            <li>Пароль API = Кодовая фраза</li>
                          </ul>
                          <img src="/Instruction/Bitget/16.jpg" class="img-fluid border-dotted"/>
                          <br><br>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="heading6">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapse6">
                          Шаг 6. Создайте бота в нашей системе
                        </button>
                      </h2>
                      <div id="collapse6" class="accordion-collapse collapse" aria-labelledby="heading6" data-bs-parent="#default-accordion-example">
                        <div class="accordion-body">
                          <p>Перейдите по указанной <a href="https://damask-trading.com/bots">ссылке</a> для создания торгового бота.</p>
                          <img src="/Instruction/Bitget/18.jpg" class="img-fluid border-dotted" />
                          <br><br>
                          <p>Активируйте бота.</p>
                          <img src="/Instruction/Bitget/19.jpg" class="img-fluid border-dotted" />
                        </div>
                  </div>
                </div>
            </div><!--end col-->.
        </div><!--end row-->
        </div><!--end row-->
        </div><!--end row-->
    </Layout>
</template>
